import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TFoot from './TFoot';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            setIsOpenPopup,
            setPartIdx,
          }}
        />
        {/* <TFoot
          {...{
            moduleData,
            isView,
          }}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
