import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import ModalCustom from '../../../../../components/common/Modal';
import PickPart from '../../../../../components/ui/PickPart';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  return (
    <>
      <thead>
        <TableCompHead
          colSpan={7}
          rowSpan={3}
          labels={['Doc. No:', 'Rev. No : ', 'Eff. Date:']}
          values={['F-HRD-25', '02', '01.04.2024']}
          heading="Mock Drill Review & Rev. Details"
        />
       
        <tr style={{ textTransform: 'uppercase' }}>
          {[
            'Sr. No.',
            'REV. NO.',
            'REV. DATE',
            'REV. DETAILS',
            'PREPARED BY',
            'APPROVED BY',
            'Action'
          ].map((head, index) => (
            <th
              key={head}
            >
              {head}
            </th>
          ))}
        </tr>
      </thead>
      
    </>
  );
};

export default THead;
