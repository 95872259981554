import { TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableCustom } from '../../../components/common';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import formatDate from '../../../components/common/formatDate';
import { useAuth } from '../../../services/auth/AuthProvider';
import { IData } from '../helpers/interface';

const tableHeaderObj = [
  [
    { text: 'Sr. No.', rowspan: 1, colspan: 1 },
    { text: 'PART NUMBER', rowspan: 1, colspan: 1 },
    { text: 'PART NAME', rowspan: 1, colspan: 1 },
    // { text: 'DRAWING NAME', rowspan: 1, colspan: 1 },
    { text: 'REV NO', rowspan: 1, colspan: 1 },
    { text: 'ISSUE TO', rowspan: 1, colspan: 1 },
    { text: 'RETRIEVAL DATE', rowspan: 1, colspan: 1 },
    { text: 'RECEIVER SIGN', rowspan: 1, colspan: 1 },
    { text: 'CREATED AT', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];
interface IProps {
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  isOpenPopup: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFileOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}
const DrawingManagementList = ({
  setId,
  isOpenPopup,
  setIsOpenPopup,
  setIsFileOpenPopup,
}: IProps) => {
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const {partId} = useParams()
  const { state } = useAuth();
  useEffect(() => {
    axios
      .get(`/api/drawingManagement/getByUserType/${partId}/${state.user?.userType}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, isOpenPopup]);
  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/drawingManagement/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        {data.map((drawing: any, index: number) => (
          <TableRow key={drawing._id}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">{drawing?.part_id?.part_number}</TableCell>
            <TableCell align="center">{drawing?.part_id?.part_name}</TableCell>
            <TableCell align="center">{drawing.rev_no}</TableCell>
            <TableCell align="center">{drawing.issue_to}</TableCell>
            <TableCell align="center">{formatDate(drawing.retrieval_date || '')}</TableCell>
            <TableCell align="center">{drawing.receiver_sign}</TableCell>
            <TableCell align="center">
              {formatDate(drawing?.createdAt || '')}
            </TableCell>
            <TableCell align="center">
              <ViewIconButton
                tooltipTitle="view"
                onClick={() => {
                  setId(drawing._id || null);
                  setIsFileOpenPopup(true);
                }}
              />
              {state.user?.userType === 2 && (
                <>
                  <EditIconButton
                    tooltipTitle="EDIT"
                    onClick={() => {
                      setId(drawing?._id! || null);
                      setIsOpenPopup(true);
                    }}
                  />
                  <DeleteIconButton
                    tooltipTitle="DELETE"
                    onClick={() => {
                      deleteDocument(drawing._id || '');
                    }}
                  />
                </>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableCustom>
    </Box>
  );
};

export default DrawingManagementList;
