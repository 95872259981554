import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../../components/common/SelectField';
import { Checkbox, Grid, MenuItem } from '@mui/material';
import TextAreaInput from '../../../../../components/common/TextAreaInput';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  console.log(moduleData);
  return (
    <tbody>
      {moduleData?.footerData &&
        moduleData.footerData?.map((spec: any, index: number) => (
          <>
            <tr key={`${index}_table_row`}>
              <td>{index + 1}</td>
              <td >
              <TableInput
                  isView={isView}
                  name="sr_no"
                  type="text"
                  value={spec.sr_no}
                  onChange={(e: any) => {
                    spec.sr_no = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  style={{ minWidth: '50px' }}
                />
              </td>
              <td style={{ minWidth: '200px' }}>
                {isView ? (
                  formatDate(spec.rev_date)
                ) : (
                  <DatePickerMui
                    value={moment(spec.rev_date)}
                    onChange={(date: any) => {
                      spec.rev_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
            
              <td>
                <TableInput
                  isView={isView}
                  name="desc"
                  type="text"
                  value={spec.desc}
                  onChange={(e: any) => {
                    spec.desc = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  style={{ minWidth: '200px' }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="prepared_by"
                  type="text"
                  value={spec.prepared_by}
                  onChange={(e: any) => {
                    spec.prepared_by = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  style={{ minWidth: '200px' }}
                />
              </td>
              <td style={{ minWidth: '250px' }}>
                <TableInput
                  isView={isView}
                  name="approved_by"
                  type="text"
                  value={spec.approved_by}
                  onChange={(e: any) => {
                    spec.approved_by = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              
              
             
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          </>
        ))
      }
    </tbody>
  );
};

export default TBody;
