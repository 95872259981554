import moment from 'moment';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { getTotalColsSpan } from '../../../../../utils/helpers/utils';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
}

const THead = ({ moduleData, updateModuleData }: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;

  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard)}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Date']}
        values={['F-15-11', '11', '24.03.2023']}
        heading={`${headerConstant.tableCompHeadTitle} ${
          moduleData?.checkList_name || ''
        }`}
      />
      <tr>
        <th colSpan={1}>CHECKSHEET NAME</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            name="checkList_name"
            type="text"
            value={moduleData.checkList_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={3}></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th colSpan={3} align="left">
        RAL Location
        </th>
        <th colSpan={3} align="left">Plant</th>
      </tr>
      <tr>
        <th colSpan={3} align="left">Part name</th>
        <th colSpan={3} align="left">Purpose of Audit</th>
      </tr>
      <tr>
        <th colSpan={3} align="left">Part No.</th>
        <th colSpan={3} align="left">Audit Date</th>
      </tr>
      <tr>
        <th colSpan={3} align="left">Auditee</th>
        <th colSpan={3} align="left">Auditor</th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
