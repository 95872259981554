import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot>
      <tr>
        <th colSpan={1} align="left">
        List of Enclosures :
        </th>
        <th colSpan={6}>
          <TableInput
            isView={isView}
            style={{}}
            name="comment"
            type="text"
            value={moduleData.comment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
         Sign Auditor :
         <TableInput
            isView={isView}
            name="auditor_sign"
            type="text"
            value={moduleData.auditor_sign}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.name, e.target.value)} />
        </th>
        <th colSpan={4} align="left">
        Sign Auditee :
        
             <TableInput
            isView={isView}
            name="auditee_sign"
            type="text"
            value={moduleData.auditee_sign}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.name, e.target.value)} />
        </th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
