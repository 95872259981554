export const checkListList = {
  childHeader: 'MOCK DRILL PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'mockDrillPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'mockDrillPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'mockDrillPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'mockDrillPlanStatusRoute/handleDeleteImage',
  forAutoComplete:
    'processRoutes/processList/processForAutoComplete',
};

export const navigationConstant = {
  checklistRouter: '/common/mockDrillPlan_StatusCheckList',
};
