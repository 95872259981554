import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../helpers/constant';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import { StyledTableHeaderTh1 } from '../../../../../components/common/tableFreeze/ColumnFrezze.styled';
import { StyledTableHeaderRow } from '../../../../../components/common/tableFreeze/RowFreeze.styled';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData?: any;
}
const THead = ({}: IProps) => {
  const yearRange = useQuery('yearRange');
  const plan_date = useQuery('plan_date');
  const isView = useQuery('isView') === 'true' ? true : false;
  const date = new Date(plan_date || '')?.getFullYear().toString().slice(-2);

  return (
    <thead
      // style={{
      //   position: 'sticky',
      //   width: '100%',
      //   top: '-.5rem',
      //   border: '1px solid black',
      //   zIndex: '1',
      //   backgroundColor: 'rgb(79, 129, 189)',
      //   color: '#fff',
      // }}
    >
      <TableCompHead
        colSpan={16 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['DOC NO-', 'REV. NO -', 'REV. DATE -']}
        values={['F-HRD-25', ' 02', '-01.04.2024']}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
       <StyledTableHeaderRow
        top={'-1px'}
        zIndex={2}
        style={{ background: '#38d9a9' }}
      >
      {['S. NO.',
       'Training Topics',
       'Plan / Actual',
      ].map((el, index) => (
        <StyledTableHeaderTh1
        key={`${index}_head`}
        minWidth={`${(el === 'S. NO.' && '50px') || '150px'}`}
        align="center"
        left={
          (index == 0 && '0px') ||
          (index == 1 && '50px') ||
          (index == 2 && '250px') ||
          (index == 3 && '400px') ||
          (index == 4 && '550px') ||
          '0px'
        }
        zIndex={1}
      >
        {el}
      </StyledTableHeaderTh1>
        // <th
        //   key={`${index}_head`}
        //   align="center"
        //   style={{
        //     textAlign: 'center',
        //     ...style1,
        //     minWidth: `${
        //       (el === 'CUSTOMER' && '160px') ||
        //       (el === 'S. NO.' && '50px') ||
        //       style1.minWidth
        //     }`,
            
        //   }}
        // >
        //   {el}
        // </th>
      ))}

      {[
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
        'JAN',
        'FEB',
        'MAR',
      ].map((el, index) => (
        <th
          key={`${index}_headcol`}
          align="center"
          style={{ textAlign: 'center', ...style2 }}
        >
          {el}-{index > 8 ? +date + 1 : date}
        </th>
      ))}
      <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
      <th style={{ textAlign: 'center', ...style1, background: '#f03e3e' }}>
        ACTION
      </th>
      </StyledTableHeaderRow>
    </thead>
  );
};

export default React.memo(THead);
