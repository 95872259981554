import React, { useState } from 'react';
import { allmonth } from '../../helpers/utils';
import { TableRowComponent } from './TableRowComponent';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import moment from 'moment';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  updateModuleData: any;
  machineData?: any;
}
const TBody = ({ moduleData, updateModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            allmonth,
            updateModuleData,
          }}
        />
      ))}
      <tr>
        <th
          colSpan={7}
          align="center"
          style={{
            background: 'rgb(99, 196, 239)',
            textTransform: 'uppercase',
          }}
        >
          Review & Rev. Details:
        </th>
      </tr>
      <tr style={{ background: '#6868c3', textTransform: 'uppercase' }}>
        {[
          '#',
          'Review Date',
          'New Rev. No.',
          'Description of Change',
          'Reason of Change',
          'Reviewed/Changed by',
          'Review Remarks',
        ].map((heading, headIndex) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>

      {moduleData?.revision_history?.map((revData: any, rev_index: number) => (
        <tr>
          <td style={{ minWidth: '120px' }}>{rev_index + 1}</td>
          <td style={{ maxWidth: '150px' }}>
            <DatePickerMui
              value={moment(revData.rev_date)}
              onChange={(date) => {
                revData.rev_date = date;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              name="rev_no"
              type="number"
              value={revData.rev_no}
              onChange={(e) => {
                revData.rev_no = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
              style={{ maxWidth: '100px' }}
            />
          </td>
          <td>
            <TableInput
              name="rev_desc"
              type="text"
              value={revData.rev_desc}
              onChange={(e) => {
                revData.rev_desc = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
              style={{ minWidth: '200px' }}
            />
          </td>
          <td>
            <TableInput
              name="rev_reason"
              type="text"
              value={revData.rev_reason}
              onChange={(e) => {
                revData.rev_reason = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
              style={{ minWidth: '200px' }}
            />
          </td>
          <td>
            <TableInput
              name="rev_review_by"
              type="text"
              value={revData.rev_review_by}
              onChange={(e) => {
                revData.rev_review_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
          <td>
            <TableInput
              name="rev_remarks"
              type="text"
              value={revData.rev_remarks}
              onChange={(e) => {
                revData.rev_remarks = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default React.memo(TBody);
