import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React, { useState } from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { getTotalColsSpan } from '../../../../../utils/helpers/utils';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import { Grid } from '@mui/material';
import { AddIconButton, RemoveIconButton } from '../../../../../components/common/button/CustomIconButton';
import ModalCustom from '../../../../../components/common/Modal';
import PickPart from '../../../../../components/ui/PickPart';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
}

const THead = ({ moduleData, updateModuleData}: IProps) => {
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  const processName = useQuery('processName');

  const handleInputChange = (
    fieldName: string,
    value: string | number | null,
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <><thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard)}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Date']}
        values={['F-15-11', '11', '24.03.2023']}
        heading={`${headerConstant.tableCompHeadTitle} ${moduleData?.checkList_name || ''}`} />
      <tr>
        <th colSpan={1} align="left">
          CHECKSHEET NAME
        </th>
        <th colSpan={2} align="left">
          {moduleData.checkList_name}
        </th>
        <th colSpan={4}></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th colSpan={3} align="left">
          RAL Location :
          <TableInput
            isView={isView}
            name="location"
            type="text"
            value={moduleData.location}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.name, e.target.value)} />
        </th>
        <th colSpan={4} align="left">
          Plant :
          <TableInput
            isView={isView}
            name="plant"
            type="text"
            value={moduleData.plant}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.name, e.target.value)} />
        </th>
      </tr>
      <tr>
        <th
          colSpan={3}
          align="left"
          style={{
            width: '280px',
            minWidth: '280px',
          }}
        >
          {isView ? (
            moduleData?.part_id && moduleData?.part_id?.part_number
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof moduleData?.part_id === 'object' &&
                    moduleData?.part_id !== null
                    ? moduleData?.part_id?.part_number
                    : moduleData?.partNumber || ''}
                  {!moduleData?.part_id && 'Part No.'}
                </p>
              </Grid>

              {moduleData.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      moduleData.part_id = null;
                      moduleData.partNumber = null;
                      moduleData.partName = null;
                      moduleData.partId = null;
                      setRender((prev: any) => !prev);
                    } } />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                    } } />
                </Grid>
              )}
            </Grid>
          )}
        </th>
        <th colSpan={4} align="left">
          Purpose of Audit
          <TableInput
            isView={isView}
            name="purpose"
            type="text"
            value={moduleData.purpose}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.name, e.target.value)} />
        </th>
      </tr>
      <tr>
        <th
          colSpan={3} align="left"
          style={{
            width: '250px',
            minWidth: '250px',
          }}>
          Part name :
          {typeof moduleData?.part_id === 'object' &&
            moduleData?.part_id !== null
            ? moduleData?.part_id?.part_name
            : moduleData?.partName || ''}
        </th>
        <th colSpan={4} align="left">
          Audit Date :
          {isView ? (
            formatDate(moduleData.audit_date)
          ) : (
            <DatePickerMui
              label=""
              value={moduleData?.audit_date ? moment(moduleData.audit_date) : null}
              onChange={(date) => {
                handleInputChange('audit_date', date);
              } } />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Auditee :
          <TableInput
            isView={isView}
            name="auditee_name"
            type="text"
            value={moduleData.auditee_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.name, e.target.value)} />
        </th>
        <th colSpan={4} align="left">
          Auditor :
          <TableInput
            isView={isView}
            name="auditor_name"
            type="text"
            value={moduleData.auditor_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.name, e.target.value)} />
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
    <ModalCustom
      title="Part List"
      openModal={isOpenPopup}
      closeModal={() => {
        setIsOpenPopup(false);
      } }
    >
        <PickPart
          {...{
            moduleData
          }} />
      </ModalCustom></>
  );
};

export default React.memo(THead);
