export const listConstant = {
  childHeader: 'Mock Drill list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'MOCK DRILL PLAN list',
  theadHeader: 'MOCK DRILL PLAN',
  extracolSpan: 1,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'mockDrillPlanRoute/monthlylist',
  deleteListurl: 'mockDrillPlanRoute',
  getListPlanByid: 'mockDrillPlanRoute',
  createListPlanByid: 'mockDrillPlanRoute',

  // -----plan--
  sync: 'mockDrillPlanRoute/handleSync',
  getAllPlanById: 'mockDrillPlanRoute/allplansbyid',
  upadateCreateAllPlan: 'mockDrillPlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'mockDrillPlanRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'MOCK DRILL PLAN',
};

export const navigationConstant = {
  planRouter: '/common/mockDrillPlan_PlanCreate',
};
