const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.NO.', style: style1 },
    { text: 'ITEM / AREAS TO BE AUDITED', style: style1 },
    { text: 'METHOD', style: style1 },
    { text: 'OBSERVATIONS', style: style1 },
    { text: 'STATUS', style: style1 },
    { text: 'ACTION', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'Mock Drill Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'MOCK DRILL CHECK SHEET FOR',
};

export const apiConstant = {
  get: 'masterCheckListRouteMockDrillPlan',
  getById: 'masterCheckListRouteMockDrillPlan',
  post: 'masterCheckListRouteMockDrillPlan',
  update: 'masterCheckListRouteMockDrillPlan',
  delete: 'masterCheckListRouteMockDrillPlan',
  forAutoComplete: 'moldRoutes/workersList/workersForAutoComplete',
  departmentAutoComplete: 'department',
};

export const tilesConstant = {
  childHeaderText: 'MOCK DRILL PLAN',
};

export const navigationConstant = {
  createEditChecklist: '/common/mockDrillPlan_ChecklistCreate',
};
