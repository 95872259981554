import {
  AiOutlineCloudUpload,
  AiOutlineDeliveredProcedure,
} from 'react-icons/ai';
import { FaFireExtinguisher } from 'react-icons/fa';
import { MdFlashlightOn } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { TbFaceIdError, TbFileReport } from 'react-icons/tb';
import { FcProcess } from 'react-icons/fc';

const tiles = [
  {
    sNo: 1,
    link: '/common/safety_listOfProcedures/?documentType=safety&heading=LIST OF PROCEDURES',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURE',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/clauseUploadList?documentType=masterProcedureClause18&heading=MASTER PROCEDURE',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'MASTER',
    span: 'PROCEDURE',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/clauseUploadList?documentType=noiseLevelInShopFloorClause18&heading=NOISE LEVEL IN SHOP FLOOR',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'NOISE LEVEL IN',
    span: 'SHOP FLOOR',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/clauseUploadList?documentType=layoutAndEscapeClause18&heading=LAYOUT AND ESCAPE ROUTES',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'LAYOUT AND ESCAPE',
    span: 'ROUTES',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/clauseUploadList?documentType=availabilityOfSafetyStandardsClause18&heading=AVAILABILITY OF SAFETY STANDARDS',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'AVAILABILITY OF SAFETY',
    span: 'STANDARDS',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/clauseUploadList?documentType=awarnessOfSafetyOperatorsClause18&heading=AWARENESS OF SAFETY TO OPERATORS',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'AWARENESS OF SAFETY',
    span: 'TO OPERATORS',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/clauseUploadList?documentType=availabilityOfPpesClause18&heading=AVAILABILITY OF PPES',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'AVAILABILITY OF',
    span: 'PPES',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/clauseUploadList?documentType=doorSensorsOnMachinesClause18&heading=DOOR SENSORS ON MACHINES',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'DOOR SENSORS',
    span: 'ON MACHINES',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/clauseUploadList?documentType=doubleHandOperationClause18&heading=DOUBLE HAND OPERATION',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'DOUBLE HAND',
    span: 'OPERATION',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/clauseUploadList?documentType=usageOfPpesByOperatorsClause18&heading=USAGE OF PPES BY OPERATORS',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'USAGE OF PPES',
    span: 'OPERATORS',
    isIcon: true,
  },
  {
    sNo: 10,
    link: '/common/clauseUploadList?documentType=accidentReportingMechanismClause18&heading=ACCIDENT REPORTING MECHANISM',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'ACCIDENT REPORTING',
    span: 'MECHANISM',
    isIcon: true,
  },
  {
    sNo: 11,
    link: '/common/clauseUploadList?documentType=countermeasuresAgainstAccidentsClause18&heading=COUNTERMEASURES AGAINST ACCIDENTS',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'COUNTERMEASURES AGAINST',
    span: 'ACCIDENTS',
    isIcon: true,
  },
  {
    sNo: 12,
    link: '/common/clauseUploadList?documentType=periodicVerificationOfCounterMeasuresClause18&heading=PERIODIC VERIFICATION OF COUNTERMEASURES',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'PERIODIC VERIFICATION OF',
    span: 'COUNTERMEASURES',
    isIcon: true,
  },
  {
    sNo: 13,
    link: '/common/clauseUploadList?documentType=firSafetyAuditClause18&heading=FIRE SAFETY AUDIT REPORT',
    isLink: true,
    clauseNo: 18,
    icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
    p: 'FIRE SAFETY',
    span: 'AUDIT REPORT',
    isIcon: true,
  },
  {
    sNo: 14,
    link: '/common/fireExtinguishers?filter=createdAt',
    isLink: true,
    clauseNo: 18,
    icon: <FaFireExtinguisher size="5rem" color="#135089" />,
    p: 'Fire Extinguisher',
    span: '',
    isIcon: true,
  },
  {
    sNo: 15,
    link: '/common/emergencyLight?filter=createdAt',
    isLink: true,
    clauseNo: 18,
    icon: <MdFlashlightOn size="5rem" color="#135089" />,
    p: 'Emergency Light',
    span: '',
    isIcon: true,
  },
  {
    sNo: 15,
    link: '/common/fireSafety?filter=createdAt',
    isLink: true,
    clauseNo: 18,
    icon: <AiFillSafetyCertificate size="5rem" color="#135089" />,
    p: 'Fire Safety',
    span: 'Equipment',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/ppeMonitoringCS',
    isLink: true,
    icon: <TbFileReport size="5rem" color="#a12222" />,
    p: 'PPE MONITORING SHEET',
    span: ' FOR SAFETY',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/dailyAccident',
    isLink: true,
    icon: <TbFileReport size="5rem" color="#a12222" />,
    p: 'DAILY ACCIDENT MONITORING',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/mockDrillTiles',
    isLink: true,
    icon: <FcProcess size="5rem" color="#003566" />,
    p: 'MOCK DRILL',
    span: 'PLAN',
    isIcon: true,
  },
];

export default tiles;
