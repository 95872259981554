import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import ModalCustom from '../../../../../components/common/Modal';
import PickPart from '../../../../../components/ui/PickPart';
import { useState } from 'react';
interface IProps {
  moduleData: any;
  updateModuleData: any;
}

const TController = ({ moduleData, updateModuleData}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, updateModuleData}} />
        <TBody
          {...{
            moduleData,
            updateModuleData,
          }}
        />
        <TFoot
          {...{
            moduleData,
            updateModuleData,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
