import { AiOutlinePartition } from 'react-icons/ai';
import { GrUser } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { MdDriveFolderUpload } from 'react-icons/md';
const tiles = [
  {
    sNo: 1,
    link: '/common/clauseUploadList?documentType=masterProcedureClause17MRM&heading=MRM UPLOAD',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'MRM',
    span: '',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/clauseUploadList?documentType=clause17QMS&heading=QMS MANUAL UPLOAD',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'QMS',
    span: 'MANUAL',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/clauseUploadList?documentType=clause17CustomerAuditReports&heading=CUSTOMER AUDIT REPORTS UPLOAD',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'CUSTOMER',
    span: 'AUDIT REPORT',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/kpiList',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlinePartition size="5rem" color="#135089" />,
    p: 'KPI',
    span: '',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/organizationChart',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'ORGANIZATION',
    span: 'CHART',
    isIcon: true,
  },
];

export default tiles;
