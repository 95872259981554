import {
  Production,
  ProductionWorkersList,
  OperatorObservanceTiles,
  OperatorObservancePlanList,
  OperatorObservancePlanCreate,
  OperatorObservanceStatus,
  OperatorObservanceChecklist,
  OperatorObservanceStatusCheckList,
  OperatorObservanceChecklistCreate,
  ListOfPdcDefects,
  CreateFillCapa,
} from '../pages/ProductionPage';
import {
  DailyProduction,
  CreateDailyProduction,
} from '../pages/ProductionPage/dailyProductionRotor';
import {
  DailyProductionStrokes,
  CreateDailyProductionStrokes,
} from '../pages/ProductionPage/dailyProductionStrokes';
import {
  DailyProductionRedBin,
  CreateDailyProductionRedBin,
} from '../pages/ProductionPage/dailyProductionRedBin';
import {
  HourlyProductionReportList,
  CreateHourlyProductionReport,
} from '../pages/ProductionPage/HourlyProductionReport';

import {
  DailyRejectionRotorList,
  CreateDailyRejectionRotor,
} from '../pages/ProductionPage/dailyProductionRotorDailyRejection';
import PDCSection from '../pages/ProductionPage/PDCSection';
import PressShop from '../pages/ProductionPage/PressShop';
import RotorSection from '../pages/ProductionPage/RotorSection';
import { Profile } from '../pages/profile';
import CNCSection from '../pages/ProductionPage/CNCSection';
import {
  CNCRedBinList,
  CreateViewCNCRedBin,
} from '../pages/ProductionPage/redBinCNC';

import {
  PressShopRedBinList,
  CreateViewPressShopRedBin,
} from '../pages/ProductionPage/redBinPressShop';
import {
  RotorSectionRedBinList,
  CreateViewRotorSectionRedBin,
} from '../pages/ProductionPage/redBinRotorSection';
import {
  DailyHourlyCNC,
  CreateDailyHourlyCNC,
} from '../pages/ProductionPage/dailyProdCNCMachining';
import UnderConstructionPage from '../underconstruction-page';
import { ClauseUploadList } from '../pages/clauseUpload';
import {
  Clause3Home,
  PdcHome,
  PressShopHome,
  RotorHome,
} from '../pages/clause3';
// clause 8 modules
import {
  AbnormalityLogBook,
  CreateAbnormalityLogBook,
} from '../pages/abnormalityLogBook';
import {
  DownloadTestPaperHome,
  EffectivenessTestHome,
  SkillEvaluationHome,
  TNITiles,
  TrainingTniHome,
  SkillEvaluationTestPaperHome,
  SkillEvaluationQuestionPaperList,
  CreateEditSkillEvaluationQuestionPaper,
  QuickViewSkillEvaluationQuestionPaper,
  SkillEvaluationDownloadTestPaperHome,
  SkillEvaluationUploadList,
} from '../pages/clause5page';
import {
  CreateForMSetup,
  CreateForMSummary,
  SummaryTableList,
} from '../pages/4m';
import MaintenanceTiles from '../pages/clause10Page/maintenance/MaintenanceLogTiles';
import {
  PreventiveMaintenaceCreate,
  PreventiveMaintenaceList,
} from '../pages/clause10Page/maintenance/preventiveMaintenancePlan';
import {
  PreventiveMaintenaceCheckListCreate,
  PreventiveMaintenaceCheckList,
} from '../pages/clause10Page/maintenance/preventiveMaintenanceStatus';
// import {
//   ChecklistList,
//   InstrumentTiles,
//   PlanCreate,
//   PlanList,
// } from '../pages/clause10Page/instrumentPlan';
// import { InstrumentMasterList } from '../pages/clause10Page/instrumentPlan/masterlistofInstrument';
import {
  AnnualPmPlanRotorCastingTiles,
  AnnualPmPlanRotorCastingList,
  AnnualPmPlanRotorCastingCreate,
  AnnualPmPlanRotorCastingChecklistList,
} from '../pages/clause10Page/AnnualPmPlanRotorCasting';
import { AnnualPmPlanRostorCastingMasterList } from '../pages/clause10Page/AnnualPmPlanRotorCasting/masterlistofInstrument';
import {
  AnnualPmPlanUtilityTiles,
  AnnualPmPlanUtilityList,
  AnnualPmPlanUtilityCreate,
  AnnualPmPlanUtilityChecklistList,
  AnnualPmPlanUtility_Checklist,
  AnnualPmPlanUtility_ChecklistCreate,
  AnnulPmPlanUtility_StatusCheckList,
} from '../pages/clause10Page/annualPmPlanUtility';
// import { AnnualPmPlanRostorCastingMasterList } from '../pages/clause10Page/AnnualPmPlanRotorCasting/masterlistofInstrument';
import { BreakDownFinalList } from '../pages/clause10Page/maintenanceLog/breakDownLogBook'; //pdirIAI/pdirFinalList';
import MaintenanceLogTiles from '../pages/clause10Page/maintenanceLog/MaintenanceLogTiles';
import {
  MtbfList,
  MtbfCreate,
} from '../pages/clause10Page/maintenanceLog/MTBF';
import {
  MttrList,
  MttrCreate,
} from '../pages/clause10Page/maintenanceLog/MTTR';
import { CriticalSparePartsList } from '../pages/masterListOfCriticalSpareParts';
import {
  Clause10,
  ManMachineMatrix,
  ManMachineMatrixList,
  // AppearancePlanTiles,
  // AppearancePlanPlanList,
  // AppearancePlanPlanCreate,
  // AppearancePlanChecklistList,
  MasterListofAppearancePlan,
  MasterListDieCasting,
  AnnualPmDieCastingPlanTiles,
  AnnualPmDieCastingPlanList,
  AnnualPmDieCastingPlanCreate,
  AnnualPmDieCastingPlanChecklistList,
  ToolRoomTiles,
  MoldList,
  CreateBeforeDieCastingCheckSheet,
  BeforeDieCastingCheckSheetList,
  MasterListofProblems,
  MasterListofToolProblems,
  BreakDownRecordsList,
  CreateBeforeDieCastingDailyCheckSheet,
  BeforeDieCastingDailyCheckSheetList,
  MonthlyBreakDownRecordsDashboard,
  MasterListofTrolly,
  MasterListofMetallicBin,
  AnnualPMPlanMetallicBinTiles,
  AnnualPMPlanMetallicBinPlanList,
  AnnualPMPlanMetallicBinPlanCreate,
  AnnualPMPlanMetallicBinStatus,
  AnnualPMPlanMetallicBinChecklist,
  AnnualPMPlanMetallicBinStatusCheckList,
  AnnualPMPlanMetallicBinChecklistCreate,
  AnnualPMPlanTrollyTiles,
  AnnualPMPlanTrollyPlanList,
  AnnualPMPlanTrollyPlanCreate,
  AnnualPMPlanTrollyStatus,
  AnnualPMPlanTrollyChecklist,
  AnnualPMPlanTrollyStatusCheckList,
  AnnualPMPlanTrollyChecklistCreate,
  PressureGauge,
  ToolBreakDownRecord,
  KpiList,
  KpiCreate,
  MasterListOfProblemsTiles,
  OrganizationChart,
} from '../pages/clause10Page';
import QuestionPaperList from '../pages/questionPaper/List';
import CreateEditQuestionPaper from '../pages/questionPaper/Create';
import QuickViewQuestionPaper from '../pages/questionPaper/View';
import TNITopicList from '../pages/clause5page/training(TNI)/TNI/createTNITopics/List';
import CreateEditTopicPaper from '../pages/clause5page/training(TNI)/TNI/createTNITopics/Create';
import QuickViewTopicPaper from '../pages/clause5page/training(TNI)/TNI/createTNITopics/View';
import SkillEvaluationList from '../pages/clause5page/skillEvaluation/List';
import AddEditViewSkillEvaluation from '../pages/clause5page/skillEvaluation/Create';
import SkillMatrixList from '../pages/clause5page/skillMatrix/List';
import AddEditViewSkillMatrix from '../pages/clause5page/skillMatrix/Create';
import {
  TniReportList,
  TniReportCreate,
} from '../pages/clause5page/training(TNI)/TNI/TniReport';
import AnnualTrainingList from '../pages/clause5page/annualTrainingPlan/List';
import CreateEditAnnalTraining from '../pages/clause5page/annualTrainingPlan/Create';
import { AnnualTrainingStatusList } from '../pages/clause5page/annualTrainingStatus';
import {
  Clause8Home,
  CustomerComplaintRecordsList,
  QualityAlertHome,
  InhouseComplaintRecordsList
} from '../pages/clause8';
import {
  InstrumentMasterList,
  MsaTiles,
  MsaPlanList,
  MsaPlanCreate,
  MsaPlanChecklist,
  CalibrationTiles,
  CalibrationPlanList,
  CalibrationPlanCreate,
  CalibrationPlanChecklist,
  MasterListOfGauge,
  Clause15Home,
} from '../pages/clause15Page';
import {
  MasterListOfVendor,
  MasterListofRecord,
  VendorList,
  SupplierRatingList,
  SupplierRatingCreate,
  VendorListForSupplierRatingForm2,
  SupplierRatingForm2List,
  SupplierRatingForm2Create,
  SupplierDefectList,
  SupplierComplaintList,
} from '../pages/clause7page';

import { CreateEditMsa, MSAList } from '../pages/qualityDepartment/MSA';

import {
  CreateInpsectionStandard,
  InspectionStandardList,
  RIDeparmentHome,
  RIPart,
  ThirdPartyMaterialTestTiles,
  ThirdPartyMaterialTestPlanList,
  ThirdPartyMaterialTestPlanCreate,
  ThirdPartyMaterialTestStatus,
  ThirdPartyMaterialTestChecklist,
  ThirdPartyMaterialTestStatusCheckList,
  ThirdPartyMaterialTestChecklistCreate,
} from '../pages/RIDeparment';
import { SamplingStd } from '../pages/RIDeparment/samplingStd';
import SupervisorHome from '../pages/supervisorHome';
import {
  InspectionReportList,
  InspectionReport,
} from '../pages/incomingInspectionReport';
import { Clause19Home } from '../pages/clause19';
import {
  Clause18Home,
  EmergencyLightChecklist,
  EmergencyLightList,
  FireExtinguisherChecklist,
  FireExtinguisherNewList,
  FireSafetyList,
  MockDrillPlan_Tiles,
  MockDrillPlan_PlanList,
  MockDrillPlan_PlanCreate,
  MockDrillPlan_Status,
  MockDrillPlan_Checklist,
  MockDrillPlan_StatusCheckList,
  MockDrillPlan_ChecklistCreate,
  ReviewDetailsMockDrillPlan,
} from '../pages/clause18';
import CreateMachineHistory from '../pages/masterlistofMachine/machineHistory/CreateMachineHistory';

import { MasterListOfCustomer } from '../pages/customer';
import { MasterListOfMachine } from '../pages/masterlistofMachine';

import MasterListOfDepartment from '../pages/department/MasterListOfDepartment';
import {
  SupplierAuditPlanTiles,
  SupplierAuditPlanList,
  SupplierAuditPlanCreate,
  SupplierAuditPlanChecklistList,
  SupplierAuditPlanChecklistCreate,
} from '../pages/clause7page/supplierAuditPlan';
import { MushinHelpDesk } from '../pages/Mushin_Help_Desk';
import { ListOfPokaYoke } from '../pages/ProductionPage/ListOfPokaYoke';
import ListOfProcedures from '../pages/listOfProcedures/listOfProcedures';
import {
  AnnualPmPlan_Tiles,
  AnnualPmPlan_PlanList,
  AnnualPmPlan_PlanCreate,
  AnnualPmPlan_Status,
  AnnualPmPlan_Checklist,
  AnnualPmPlan_ChecklistCreate,
  AnnulPmPlan_StatusCheckList,
} from '../pages/clause10Page/annualPMPlan';
import { clause5new } from './common/clause5';
import {
  HPDCChecksheetsHome,
  DailyMachineCheckSheetsList,
  CreateDailyMachineCheckSheet,
  OperatorCheckSheetsList,
  CreateOperatorCheckSheet,
  SupervisorCheckSheetsList,
  CreateSupervisorCheckSheet,
} from '../pages/hpdcChecksheets';
import {
  SupplierAuditPlanAprilToMarchChecklistCreate,
  SupplierAuditPlanAprilToMarchChecklistList,
  SupplierAuditPlanAprilToMarchCreate,
  SupplierAuditPlanAprilToMarchList,
  SupplierAuditPlanAprilToMarchTiles,
} from '../pages/clause7page/supplierAuditPlanAprilToMarch';
import { CreateDegassingCheckSheet } from '../pages/degassingFrequency/index';
import {
  CreateDailyAccidentMonitoring,
  DailyAccidentMonitoring,
} from '../pages/ProductionPage/dailyAccidentmonitoringCS';
import {
  DailyPPEMonitoring,
  CreateDailyPPEMonitoring,
} from '../pages/ProductionPage/ppeMonitoringCS';
import {
  DiePmPlan_Checklist,
  DiePmPlan_ChecklistCreate,
  DiePmPlan_PlanCreate,
  DiePmPlan_PlanList,
  DiePmPlan_Status,
  DiePmPlan_StatusCheckList,
  DiePmPlan_Tiles,
} from '../pages/clause10Page/toolRoom/diePMPlan';

import {
  CreateProcessParameterStd,
  ProcessParameterList,
} from '../pages/ProductionPage/processParameterStd';
import {
  QualityDeparmentHome,
  ScrapNoteList,
  CreateScrapNote,
  ListOfDefects,
  LastShotReportCreate,
  LastShotReportTableList,
  CheckSheetFormatsChecklist,
  CheckSheetFormatsChecklistCreate,
  FillCheckSheetChecklist,
  FillCheckSheetChecklistCreate,
} from '../pages/qualityDepartment';
import {
  DieTrialRecord,
  CreateDieTrialRecord,
} from '../pages/clause10Page/dieTrialRecord';
import ToolsList from '../pages/masterlisofToolsOrDie/List';
import ToolsHistory from '../pages/masterlisofToolsOrDie/toolsHistory/CreateHistory';
import {
  CreateLuxLevelSheetsList,
  LuxLevelSheetsList,
} from '../pages/clause10Page/luxLevelSheet';
import {
  MSAPlanNewChecklist,
  MSAPlanNewChecklistCreate,
  MSAPlanNewPlanCreate,
  MSAPlanNewPlanList,
  MSAPlanNewStatus,
  MSAPlanNewStatusCheckList,
  MSAPlanNewTiles,
} from '../pages/qualityDepartment/msaPlanNew';
import {
  AttributeMsaPlanNewChecklist,
  AttributeMsaPlanNewChecklistCreate,
  AttributeMsaPlanNewPlanCreate,
  AttributeMsaPlanNewPlanList,
  AttributeMsaPlanNewStatus,
  AttributeMsaPlanNewStatusCheckList,
  AttributeMsaPlanNewTiles,
} from '../pages/qualityDepartment/attributeMsaPlanNew';
import {
  SpcPlanNewChecklist,
  SpcPlanNewChecklistCreate,
  SpcPlanNewCreate,
  SpcPlanNewList,
  SpcPlanNewStatus,
  SpcPlanNewStatusCheckList,
  SpcPlanNewTiles,
} from '../pages/qualityDepartment/spcPlanNew';
import {
  MasterList,
  MasterListofCutSection,
  MasterListOfPackingStd,
  MasterListOfPfd,
  MasterListofPFMEA,
  MasterListofProcessSheet,
} from '../pages/masterList';
import {
  DieLoadingChecklistCreate,
  DieLoadingChecklistTableList,
} from '../pages/clause10Page/toolRoom';
import { DrawingnManagement } from '../pages/drawingManagement';
import {
  ProcessAuditPlan_Checklist,
  ProcessAuditPlan_ChecklistCreate,
  ProcessAuditPlan_PlanCreate,
  ProcessAuditPlan_PlanList,
  ProcessAuditPlan_Status,
  ProcessAuditPlan_StatusCheckList,
  ProcessAuditPlan_Tiles,
} from '../pages/clause6/processAuditPlan';
import {
  LayoutPlanNewChecklist,
  LayoutPlanNewChecklistCreate,
  LayoutPlanNewCreate,
  LayoutPlanNewList,
  LayoutPlanNewStatus,
  LayoutPlanNewStatusCheckList,
  LayoutPlanNewTiles,
} from '../pages/clause6/layoutInspectionPlan';
import {
  ProductAuditPlanChecklistList,
  ProductAuditPlanCreate,
  ProductAuditPlanList,
  ProductAuditPlanTiles,
} from '../pages/clause6/productAuditPlanOld';
import {
  MasterListOfProcess,
  ProductAuditReportCreate,
  ProductAuditReportList,
  InternalAuditTiles,
  InternalAuditPlanList,
  InternalAuditPlanCreate,
  InternalAuditStatus,
  InternalAuditChecklist,
  InternalAuditStatusCheckList,
  InternalAuditChecklistCreate,
  QualifiedEditors,
  InternalAuditorSelectionCriteria,
  // InternalAuditNcSummaryTracker,
  // CreateInternalAuditNcSummaryTracker,
} from '../pages/clause6';

import {
  InternalAuditNcSummaryTracker,
  CreateInternalAuditNcSummaryTracker,
} from '../pages/clause6/internalAuditNcSummaryTracker';
import {
  BreakDownSlipList,
  CreateBreakDownSlip,
} from '../pages/clause10Page/toolRoom/breakDownSlip';
import {
  PoisonTestRecordList,
  CreatePoisonTestRecord,
  PoisonTestTiles,
  PoisonTestPlanList,
  PoisonTestPlanCreate,
  PoisonTestStatus,
  PoisonTestChecklist,
  PoisonTestStatusCheckList,
  PoisonTestChecklistCreate,
} from '../pages/clause11';
import {
  SimilarPartManagementList,
  ReasonsForRejectionList,
  ReworkRegisterList,
  ReworkRegisterCreate,
} from '../pages/clause12Page';
import { PokeYokeCheckSheet } from '../pages/ProductionPage/pokeyokeChecksheet/Index';
import {
  ProcessValidationList,
  ProcessValidationReport,
} from '../pages/ProductionPage/processValidationSheet';
import {
  ProcessParameterSheetList,
  ProcessParameterSheetReport,
} from '../pages/ProductionPage/processParameterSheet';
import {
  AnnualPmPlanNewUtility_Tiles,
  AnnualPmPlanNewUtility_PlanList,
  AnnualPmPlanNewUtility_PlanCreate,
  AnnualPmPlanNewUtility_Status,
  AnnualPmPlanNewUtility_Checklist,
  AnnulPmPlanNewUtility_StatusCheckList,
  AnnualPmPlanNewUtility_ChecklistCreate,
} from '../pages/clause10Page/annualPmPlanNewUtility';
import { AppearancePlanMachineHistoryCard } from '../pages/clause10Page/masterlist/machineHistory/CreateAppearancePlanMachineHistory';
import { elements } from 'chart.js';
import { AccidentRecordForm } from '../pages/ProductionPage/dailyAccidentmonitoringCS/accidentRecord';
import { MRMList, CreateMRMList } from '../pages/mrm';
import {
  CreateMatrix,
  CreateNpdTimePlan,
  DevlopmentTrackerList,
  MasterListOfProject,
  MatrixList,
  NpdPartList,
  TimePlanList,
} from '../pages/clause1';
import { InstrumentHistoryCard } from '../pages/clause15Page/masterlistofInstrument';

// customer rating
import { CustomerList, CustomerRatingList } from '../pages/customerRating';

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

const qualityDepartment = [
  {
    path: 'qualityDepartmentHome',
    element: <QualityDeparmentHome />,
  },
  // scrap note
  {
    path: 'scrapNoteList',
    element: <ScrapNoteList />,
  },
  {
    path: 'createEditScrapNote/:id?',
    element: <CreateScrapNote />,
  },
  {
    path: 'masterListOfProblemOrDefects',
    element: <ListOfDefects />,
  },

  // msa plan new
  {
    path: 'msaPlanNewTiles',
    element: <MSAPlanNewTiles />,
  },
  {
    path: 'msaPlanNewPlanList',
    element: <MSAPlanNewPlanList />,
  },
  {
    path: 'msaPlanNewCreate/:id',
    element: <MSAPlanNewPlanCreate />,
  },
  {
    path: 'msaPlanNewStatus',
    element: <MSAPlanNewStatus />,
  },
  {
    path: 'msaPlanNewChecklist',
    element: <MSAPlanNewChecklist />,
  },
  {
    path: 'msaPlanNewStatusCheckList/:id?',
    element: <MSAPlanNewStatusCheckList />,
  },
  {
    path: 'msaPlanNewChecklistCreate/:id?',
    element: <MSAPlanNewChecklistCreate />,
  },

  // msa new  end

  // attribute msa plan new
  {
    path: 'attributeMsaPlanNewTiles',
    element: <AttributeMsaPlanNewTiles />,
  },
  {
    path: 'attributeMsaPlanNewPlanList',
    element: <AttributeMsaPlanNewPlanList />,
  },
  {
    path: 'attributeMsaPlanNewCreate/:id',
    element: <AttributeMsaPlanNewPlanCreate />,
  },
  {
    path: 'attributeMsaPlanNewStatus',
    element: <AttributeMsaPlanNewStatus />,
  },
  {
    path: 'attributeMsaPlanNewChecklist',
    element: <AttributeMsaPlanNewChecklist />,
  },
  {
    path: 'attributeMsaPlanNewStatusCheckList/:id?',
    element: <AttributeMsaPlanNewStatusCheckList />,
  },
  {
    path: 'attributeMsaPlanNewChecklistCreate/:id?',
    element: <AttributeMsaPlanNewChecklistCreate />,
  },

  //attribute msa  new end

  // spc plan new
  {
    path: 'spcPlanNewTiles',
    element: <SpcPlanNewTiles />,
  },
  {
    path: 'spcPlanNewPlanList',
    element: <SpcPlanNewList />,
  },
  {
    path: 'spcPlanNewCreate/:id',
    element: <SpcPlanNewCreate />,
  },
  {
    path: 'spcPlanNewStatus',
    element: <SpcPlanNewStatus />,
  },
  {
    path: 'spcPlanNewChecklist',
    element: <SpcPlanNewChecklist />,
  },
  {
    path: 'spcPlanNewStatusCheckList/:id?',
    element: <SpcPlanNewStatusCheckList />,
  },
  {
    path: 'spcPlanNewChecklistCreate/:id?',
    element: <SpcPlanNewChecklistCreate />,
  },

  //spc  new end
  //last shot report
  {
    path: 'lastShotReportCreate/:id?',
    element: <LastShotReportCreate />,
  },
  {
    path: 'lastShotReportTableList',
    element: <LastShotReportTableList />,
  },
  {
    path: 'checkSheetFormatsChecklist',
    element: <CheckSheetFormatsChecklist />,
  },
  {
    path: 'checkSheetFormatsChecklistCreate/:id?',
    element: <CheckSheetFormatsChecklistCreate />,
  },
  {
    path: 'fillCheckSheetChecklist',
    element: <FillCheckSheetChecklist />,
  },
  {
    path: 'fillCheckSheetChecklistCreate/:id?',
    element: <FillCheckSheetChecklistCreate />,
  },
];
export const other: routes[] = [
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'pdc',
    element: <PDCSection />,
  },
  {
    path: 'rotorSection',
    element: <RotorSection />,
  },
  {
    path: 'pressShop',
    element: <PressShop />,
  },
  {
    path: 'cnc',
    element: <CNCSection />,
  },
];

const supplierAuditPlan = [
  {
    path: 'supplierAuditPlanTiles',
    element: <SupplierAuditPlanTiles />,
  },
  {
    path: 'supplierAuditPlanList',
    element: <SupplierAuditPlanList />,
  },
  {
    path: 'supplierAuditPlanCreate/:id',
    element: <SupplierAuditPlanCreate />,
  },
  {
    path: 'supplierAuditPlanChecklistList',
    element: <SupplierAuditPlanChecklistList />,
  },
  {
    path: 'supplierAuditPlanChecklistCreate/:moduleId?',
    element: <SupplierAuditPlanChecklistCreate />,
  },
  // supplier Audit Plan April To March
  {
    path: 'supplierAuditPlanAprilToMarchTiles',
    element: <SupplierAuditPlanAprilToMarchTiles />,
  },
  {
    path: 'supplierAuditPlanAprilToMarchList',
    element: <SupplierAuditPlanAprilToMarchList />,
  },
  {
    path: 'supplierAuditPlanAprilToMarchCreate/:id',
    element: <SupplierAuditPlanAprilToMarchCreate />,
  },
  {
    path: 'supplierAuditPlanAprilToMarchChecklistList',
    element: <SupplierAuditPlanAprilToMarchChecklistList />,
  },
  {
    path: 'supplierAuditPlanAprilToMarchChecklistCreate/:moduleId?',
    element: <SupplierAuditPlanAprilToMarchChecklistCreate />,
  },
];

const annualPmPlan = [
  // new plan
  {
    path: 'annualPmPlan_Tiles',
    element: <AnnualPmPlan_Tiles />,
  },
  {
    path: 'annualPmPlan_PlanList',
    element: <AnnualPmPlan_PlanList />,
  },
  {
    path: 'annualPmPlan_PlanCreate/:id',
    element: <AnnualPmPlan_PlanCreate />,
  },
  {
    path: 'annualPmPlan_Status',
    element: <AnnualPmPlan_Status />,
  },
  {
    path: 'annualPmPlan_Checklist',
    element: <AnnualPmPlan_Checklist />,
  },
  {
    path: 'annualPmPlan_ChecklistCreate/:id?',
    element: <AnnualPmPlan_ChecklistCreate />,
  },
  {
    path: 'annulPmPlan_StatusCheckList/:id?',
    element: <AnnulPmPlan_StatusCheckList />,
  },
];

const annualPmPlanNewUtility = [
  // new plan
  {
    path: 'annualPmPlanNewUtility_Tiles',
    element: <AnnualPmPlanNewUtility_Tiles />,
  },
  {
    path: 'annualPmPlanNewUtility_PlanList',
    element: <AnnualPmPlanNewUtility_PlanList />,
  },
  {
    path: 'annualPmPlanNewUtility_PlanCreate/:id',
    element: <AnnualPmPlanNewUtility_PlanCreate />,
  },
  {
    path: 'annualPmPlanNewUtility_Status',
    element: <AnnualPmPlanNewUtility_Status />,
  },
  {
    path: 'annualPmPlanNewUtility_Checklist',
    element: <AnnualPmPlanNewUtility_Checklist />,
  },
  {
    path: 'annualPmPlanNewUtility_ChecklistCreate/:id?',
    element: <AnnualPmPlanNewUtility_ChecklistCreate />,
  },
  {
    path: 'annulPmPlanNewUtility_StatusCheckList/:id?',
    element: <AnnulPmPlanNewUtility_StatusCheckList />,
  },
];

const diePmPlan = [
  // new plan
  {
    path: 'diePmTiles',
    element: <DiePmPlan_Tiles />,
  },
  {
    path: 'diePmPlan_PlanList',
    element: <DiePmPlan_PlanList />,
  },
  {
    path: 'diePmPlan_PlanCreate/:id',
    element: <DiePmPlan_PlanCreate />,
  },
  {
    path: 'diePmPlan_Status',
    element: <DiePmPlan_Status />,
  },
  {
    path: 'diePmPlan_Checklist',
    element: <DiePmPlan_Checklist />,
  },
  {
    path: 'diePmPlan_ChecklistCreate/:id?',
    element: <DiePmPlan_ChecklistCreate />,
  },
  {
    path: 'diePmPlan_StatusCheckList/:id?',
    element: <DiePmPlan_StatusCheckList />,
  },
];
const clause6 = [
  {
    path: 'masterListOfProcess',
    element: <MasterListOfProcess />,
  },
  // layout plan new
  {
    path: 'layoutPlanNewTiles',
    element: <LayoutPlanNewTiles />,
  },
  {
    path: 'layoutPlanNewPlanList',
    element: <LayoutPlanNewList />,
  },
  {
    path: 'layoutPlanNewCreate/:id',
    element: <LayoutPlanNewCreate />,
  },
  {
    path: 'layoutPlanNewStatus',
    element: <LayoutPlanNewStatus />,
  },
  {
    path: 'layoutPlanNewChecklist',
    element: <LayoutPlanNewChecklist />,
  },
  {
    path: 'layoutPlanNewStatusCheckList/:id?',
    element: <LayoutPlanNewStatusCheckList />,
  },
  {
    path: 'layoutPlanNewChecklistCreate/:id?',
    element: <LayoutPlanNewChecklistCreate />,
  },

  //layout plan  new end
  // process audit plan new
  {
    path: 'processAuditTiles',
    element: <ProcessAuditPlan_Tiles />,
  },
  {
    path: 'processAuditPlan_PlanList',
    element: <ProcessAuditPlan_PlanList />,
  },
  {
    path: 'processAuditPlan_PlanCreate/:id',
    element: <ProcessAuditPlan_PlanCreate />,
  },
  {
    path: 'processAuditPlan_Status',
    element: <ProcessAuditPlan_Status />,
  },
  {
    path: 'processAuditPlan_Checklist',
    element: <ProcessAuditPlan_Checklist />,
  },
  {
    path: 'processAuditPlan_ChecklistCreate/:id?',
    element: <ProcessAuditPlan_ChecklistCreate />,
  },
  {
    path: 'processAuditPlan_StatusCheckList/:id?',
    element: <ProcessAuditPlan_StatusCheckList />,
  },
  //process audit plan  new end
  //product  audit plan old
  {
    path: 'productAuditPlanTiles',
    element: <ProductAuditPlanTiles />,
  },
  {
    path: 'productAuditPlanList',
    element: <ProductAuditPlanList />,
  },
  {
    path: 'productAuditPlanCreate/:id',
    element: <ProductAuditPlanCreate />,
  },
  {
    path: 'productAuditPlanChecklistList',
    element: <ProductAuditPlanChecklistList />,
  },
  {
    path: 'productAuditReportCreate/:partId/:id?',
    element: <ProductAuditReportCreate />,
  },
  {
    path: 'productAuditReportList/:partId',
    element: <ProductAuditReportList />,
  },
  // end product audit plan
  // start internal Audit Plan

  {
    path: 'qualifiedAuditors',
    element: <QualifiedEditors />,
  },
  {
    path: 'internalAuditorSelection',
    element: <InternalAuditorSelectionCriteria />,
  },
  {
    path: 'internalAuditNcSummaryTracker',
    element: <InternalAuditNcSummaryTracker />,
  },
  {
    path: 'internalAuditNcSummaryTracker/create/:id',
    element: <CreateInternalAuditNcSummaryTracker />,
  },
  {
    path: 'internalAuditTiles',
    element: <InternalAuditTiles />,
  },
  {
    path: 'internalAuditPlanList',
    element: <InternalAuditPlanList />,
  },
  {
    path: 'internalAuditPlanCreate/:id',
    element: <InternalAuditPlanCreate />,
  },
  {
    path: 'internalAuditStatus',
    element: <InternalAuditStatus />,
  },
  {
    path: 'internalAuditChecklist',
    element: <InternalAuditChecklist />,
  },
  {
    path: 'internalAuditStatusCheckList/:id?',
    element: <InternalAuditStatusCheckList />,
  },
  {
    path: 'internalAuditChecklistCreate/:id?',
    element: <InternalAuditChecklistCreate />,
  },
  // end internal Audit Plan
];

const inspectionReportRoute = [
  {
    path: 'inspectionReportList',
    element: <InspectionReportList />,
  },
  {
    path: 'inspectionReport/:partId/:inspectionReportId?',
    element: <InspectionReport />,
  },
];
const processValidationRoute = [
  {
    path: 'processValidationList',
    element: <ProcessValidationList />,
  },
  {
    path: 'processValidationReport/:processStdId/:moduleId?',
    element: <ProcessValidationReport />,
  },
];
const processParameterSheetRoute = [
  {
    path: 'processParameterList',
    element: <ProcessParameterSheetList />,
  },
  {
    path: 'processParameterReport/:processStdId/:moduleId?',
    element: <ProcessParameterSheetReport />,
  },
];
export const productions = [
  {
    path: 'productionWorkersList',
    element: <ProductionWorkersList />,
  },
  {
    path: 'production_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'production',
    element: <Production />,
  },
  // {
  //   path: 'dailyProductionList',
  //   element: <DailyProduction />,
  // },

  // --------------------------- rotor section -------------------------------------------------------------------------
  // daily prod rotor report
  {
    path: 'rotorSection/dailyProductionList',
    element: <DailyProduction />,
  },
  {
    path: 'createEditDailyProduction/:id?',
    element: <CreateDailyProduction />,
  },

  // daily rotor rejection report
  {
    path: 'rotorSection/dailyProductionRotorDailyRejectionList',
    element: <DailyRejectionRotorList />,
  },
  {
    path: 'createEditDailyProductionRotorDailyRejection/:id?',
    element: <CreateDailyRejectionRotor />,
  },
  // daily production redbin list (doubt it is to be deleted or not)
  // {
  //   path: 'rotorSection/dailyProductionRedBinList',
  //   element: <DailyRejectionRotorList />,
  // },
  // {
  //   path: 'createEditDailyProductionRotorDailyRejection/:id?',
  //   element: <CreateDailyRejectionRotor />,
  // },
  // redbin prashant
  {
    path: 'rotorSection/redbinRotorSection',
    element: <RotorSectionRedBinList />,
  },
  {
    path: 'rotorSection/createEditRedbinRotorSection/:id?',
    element: <CreateViewRotorSectionRedBin />,
  },

  // ----------------------------------end of rotor section -----------------------------------------------------------------------

  // -------------------------------------------pdc section -------------------------------------------------------------------------
  // hourly report
  {
    path: 'pdc/rotorHourlyProductionList',
    element: <HourlyProductionReportList />,
  },
  {
    path: 'createHourlyProductionReports/:id?',
    element: <CreateHourlyProductionReport />,
  },

  // prodRed bin pdc section
  {
    path: 'pdc/dailyProductionRedBinList',
    element: <DailyProductionRedBin />,
  },
  {
    path: 'createEditDailyProductionRedBin/:id?',
    element: <CreateDailyProductionRedBin />,
  },

  // ---------------------------------------------- press shop section ----------------------------------------------
  // dailyprod strokes
  {
    path: 'pressShop/dailyProductionStrokesList',
    element: <DailyProductionStrokes />,
  },
  {
    path: 'createEditDailyProductionStrokes/:id?',
    element: <CreateDailyProductionStrokes />,
  },

  {
    path: 'pressShop/redbinPressShop',
    element: <PressShopRedBinList />,
  },
  {
    path: 'pressShop/createEditRedbinPressShop/:id?',
    element: <CreateViewPressShopRedBin />,
  },

  // redbin

  // ---------------------------------------------- end of press shop section ----------------------------------------------

  // ------------------------------------------------- cnc secction --------------------------------------------------------

  // hourly prod cnc
  {
    path: 'cnc/dailyHourlyProdCNCList',
    element: <DailyHourlyCNC />,
  },
  {
    path: 'cnc/createEditDailyHourlyProdCNC/:id?',
    element: <CreateDailyHourlyCNC />,
  },

  //list of poka yoke
  {
    path: 'listOfPokaYoke/:id?',
    element: <ListOfPokaYoke />,
  },

  // redbin cnc
  {
    path: 'cnc/redbinCNC',
    element: <CNCRedBinList />,
  },
  {
    path: 'cnc/createEditRedbinCNC/:id?',
    element: <CreateViewCNCRedBin />,
  },
  // Daily Acident Monitoring Check Sheet
  {
    path: 'dailyAccident',
    element: <DailyAccidentMonitoring />,
  },
  {
    path: 'accidentRecord',
    element: <AccidentRecordForm />,
  },
  {
    path: 'createDailyAccident/:id?',
    element: <CreateDailyAccidentMonitoring />,
  },
  // PPE Monitoring Check Sheet
  {
    path: 'ppeMonitoringCS',
    element: <DailyPPEMonitoring />,
  },
  {
    path: 'createPpeMCS/:id?',
    element: <CreateDailyPPEMonitoring />,
  },
  // new plan
  {
    path: 'operatorObservanceTiles',
    element: <OperatorObservanceTiles />,
  },
  {
    path: 'operatorObservancePlanList',
    element: <OperatorObservancePlanList />,
  },
  {
    path: 'operatorObservancePlanCreate/:id',
    element: <OperatorObservancePlanCreate />,
  },
  {
    path: 'operatorObservanceStatus',
    element: <OperatorObservanceStatus />,
  },
  {
    path: 'operatorObservanceChecklist',
    element: <OperatorObservanceChecklist />,
  },
  {
    path: 'operatorObservanceStatusCheckList/:id?',
    element: <OperatorObservanceStatusCheckList />,
  },
  {
    path: 'operatorObservanceChecklistCreate/:id?',
    element: <OperatorObservanceChecklistCreate />,
  },
  {
    path: 'pokeyokeChecksheet',
    element: <PokeYokeCheckSheet />,
  },

  // Poison Test plan
  {
    path: 'poisonTestTiles',
    element: <PoisonTestTiles />,
  },
  {
    path: 'poisonTestPlanList',
    element: <PoisonTestPlanList />,
  },
  {
    path: 'poisonTestPlanCreate/:id',
    element: <PoisonTestPlanCreate />,
  },
  {
    path: 'poisonTestStatus',
    element: <PoisonTestStatus />,
  },
  {
    path: 'poisonTestChecklist',
    element: <PoisonTestChecklist />,
  },
  {
    path: 'poisonTestStatusCheckList/:id?',
    element: <PoisonTestStatusCheckList />,
  },
  {
    path: 'poisonTestChecklistCreate/:id?',
    element: <PoisonTestChecklistCreate />,
  },
  {
    path: 'listOfPdcDefects',
    element: <ListOfPdcDefects />,
  },
  {
    path: 'createFillCapa/:id?',
    element: <CreateFillCapa />,
  },
];
const clause5Eduction = [
  {
    path: 'effectivenessTestPaperHome',
    element: <EffectivenessTestHome />,
  },
  {
    path: 'effectivenessTestPaperHome',
    element: <EffectivenessTestHome />,
  },
  {
    path: 'downloadTestPaper',
    element: <DownloadTestPaperHome />,
  },
  {
    path: 'createTestPaper',
    element: <QuestionPaperList />,
  },
  {
    path: 'createEditQuestionPaper/:id?',
    element: <CreateEditQuestionPaper />,
  },
  {
    path: 'quick_view_questionpaper/:id',
    element: <QuickViewQuestionPaper />,
  },
  {
    path: 'trainingTniHome',
    element: <TrainingTniHome />,
  },
  {
    path: 'tniTiles',
    element: <TNITiles />,
  },
  {
    path: 'topicPaper/list',
    element: <TNITopicList />,
  },
  {
    path: 'createEditTopicPaper/:id?',
    element: <CreateEditTopicPaper />,
  },
  {
    path: 'quick_view_topic_paper/:id',
    element: <QuickViewTopicPaper />,
  },
  // skill Evalauation Test Paper
  {
    path: 'skillEvaluationTestPaperHome',
    element: <SkillEvaluationTestPaperHome />,
  },
  {
    path: 'createSkillEvaluationTestPaper',
    element: <SkillEvaluationQuestionPaperList />,
  },
  {
    path: 'createEditSkillEvaluationQuestionPaper/:id?',
    element: <CreateEditSkillEvaluationQuestionPaper />,
  },
  {
    path: 'quickViewSkillEvaluationQuestionPaper/:id',
    element: <QuickViewSkillEvaluationQuestionPaper />,
  },
  //
  {
    path: 'downloadSkillEvaluationTestPaper',
    element: <SkillEvaluationDownloadTestPaperHome />,
  },
  {
    path: 'skillEvaluationUploadList',
    element: <SkillEvaluationUploadList />,
  },
  {
    path: 'skillEvaluationHome',
    element: <SkillEvaluationHome />,
  },
  {
    path: 'SkillEvaluationList',
    element: <SkillEvaluationList />,
  },
  {
    path: 'createEditViewSkillEvaluation/:id',
    element: <AddEditViewSkillEvaluation />,
  },
  {
    path: 'skillMatrixList',
    element: <SkillMatrixList />,
  },
  {
    path: 'createEditViewSkillMatrix/:id',
    element: <AddEditViewSkillMatrix />,
  },
  {
    path: 'tniList/data',
    element: <TniReportList />,
  },
  {
    path: 'createEditTNI/report/:id',
    element: <TniReportCreate />,
  },
  {
    path: 'annualTrainingList',
    element: <AnnualTrainingList />,
  },
  {
    path: 'create_edit_annualTraning/list/:id?',
    element: <CreateEditAnnalTraining />,
  },
  {
    path: 'trainginStatusList',
    element: <AnnualTrainingStatusList />,
  },
];

const clause3ForM = [
  {
    path: 'clause3',
    element: <Clause3Home />,
  },
  {
    path: 'changing_management_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: '4mPdc',
    element: <PdcHome />,
  },
  {
    path: '4mPressShop',
    element: <PressShopHome />,
  },
  {
    path: '4mRotor',
    element: <RotorHome />,
  },
];

const clause8Modules = [
  // abnormality Log book
  {
    path: 'handling_abnormility_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'clause8',
    element: <Clause8Home />,
  },
  {
    path: 'abnormalityLogBookList',
    element: <AbnormalityLogBook />,
  },
  {
    path: 'createAbnormalityLogBook/:id?',
    element: <CreateAbnormalityLogBook />,
  },
  {
    path: 'customerComplaintRecords',
    element: <CustomerComplaintRecordsList />,
  },
  {
    path: 'qualityAlert',
    element: <QualityAlertHome />,
  },
  {
    path: 'inhouseComplaintRecords',
    element: <InhouseComplaintRecordsList />,
  },
];
const clause11Page = [
  // Poison Test Record
  {
    path: 'poisonTestRecordList',
    element: <PoisonTestRecordList />,
  },
  {
    path: 'createPoisonTestRecord/:id?',
    element: <CreatePoisonTestRecord />,
  },
];
const clause12Page = [
  {
    path: 'similarPartManagementList',
    element: <SimilarPartManagementList />,
  },
  {
    path: 'reasonsForRejectionList',
    element: <ReasonsForRejectionList />,
  },
  {
    path: 'reworkRegisterList',
    element: <ReworkRegisterList />,
  },
  {
    path: 'reworkRegisterCreate/:id?',
    element: <ReworkRegisterCreate />,
  },
];
const clause15Modules = [
  {
    path: 'adequate_testing_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'clause15',
    element: <Clause15Home />,
  },
  {
    path: 'MSAList',
    element: <MSAList />,
  },
  {
    path: 'CreateEditMsa/:partId/:id?',
    element: <CreateEditMsa />,
  },
  {
    path: 'instrumentMasterlist',
    element: <InstrumentMasterList />,
  },
  {
    path: 'instrumentMasterlist/history/:id',
    element: <InstrumentHistoryCard />,
  },
  {
    path: 'msaTiles',
    element: <MsaTiles />,
  },
  {
    path: 'msaPlanList',
    element: <MsaPlanList />,
  },
  {
    path: 'msaPlanCreate/:id',
    element: <MsaPlanCreate />,
  },
  {
    path: 'msaPlanChecklist',
    element: <MsaPlanChecklist />,
  },

  {
    path: 'calibrationTiles',
    element: <CalibrationTiles />,
  },
  {
    path: 'calibrationPlanList',
    element: <CalibrationPlanList />,
  },
  {
    path: 'calibrationPlanCreate/:id',
    element: <CalibrationPlanCreate />,
  },
  {
    path: 'calibrationPlanChecklist',
    element: <CalibrationPlanChecklist />,
  },
  {
    path: 'gaugeMasterlist',
    element: <MasterListOfGauge />,
  },
];

const forMSummary = [
  {
    path: 'create4mSummary',
    element: <CreateForMSummary />,
  },
  {
    path: '4mSummaryList',
    element: <SummaryTableList />,
  },
  {
    path: 'create4mSetup/:partId/:rowId/:setupId?',
    element: <CreateForMSetup />,
  },
];

const clause7 = [
  {
    path: 'masterListOfVendor',
    element: <MasterListOfVendor />,
  },
  {
    path: 'approvedSupplierList',
    element: <VendorList />,
  },
  {
    path: 'supplierRatingList/:supplierId',
    element: <SupplierRatingList />,
  },
  {
    path: 'supplierRatingCreate/:id',
    element: <SupplierRatingCreate />,
  },
  {
    path: 'supplierRating/form2/vendorlist',
    element: <VendorListForSupplierRatingForm2 />,
  },
  {
    path: 'supplierRating/form2/list/:supplierId',
    element: <SupplierRatingForm2List />,
  },
  {
    path: 'supplierRating/form2/create/:id',
    element: <SupplierRatingForm2Create />,
  },
  {
    path: 'supplier/defect/list',
    element: <SupplierDefectList />,
  },
  {
    path: 'SupplierComplaintRegister',
    element: <SupplierComplaintList />,
  },
  {
    path: 'masterListOfRecord',
    element: <MasterListofRecord />,
  },
];
const clause19 = [
  {
    path: 'legal_compliance_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'clause19',
    element: <Clause19Home />,
  },
];

const clause18 = [
  {
    path: 'safety_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'clause18',
    element: <Clause18Home />,
  },
  {
    path: 'fireExtinguishers',
    element: <FireExtinguisherNewList />,
  },
  {
    path: 'fireExtinguishers/checklist/:id',
    element: <FireExtinguisherChecklist />,
  },
  {
    path: 'emergencyLight',
    element: <EmergencyLightList />,
  },
  {
    path: 'emergencyLight/checklist/:id',
    element: <EmergencyLightChecklist />,
  },
  {
    path: 'fireSafety',
    element: <FireSafetyList />,
  },
  // mock drill plan
  {
    path: 'mockDrillTiles',
    element: <MockDrillPlan_Tiles />,
  },
  {
    path: 'mockDrillPlan_PlanList',
    element: <MockDrillPlan_PlanList />,
  },
  {
    path: 'mockDrillPlan_PlanCreate/:id',
    element: <MockDrillPlan_PlanCreate />,
  },
  {
    path: 'mockDrillPlan_Status',
    element: <MockDrillPlan_Status />,
  },
  {
    path: 'mockDrillPlan_Checklist',
    element: <MockDrillPlan_Checklist />,
  },
  {
    path: 'mockDrillPlan_ChecklistCreate/:id?',
    element: <MockDrillPlan_ChecklistCreate />,
  },
  {
    path: 'mockDrillPlan_StatusCheckList/:id?',
    element: <MockDrillPlan_StatusCheckList />,
  },
  {
    path: 'ReviewDetailsMockDrillPlan/:id',
    element: <ReviewDetailsMockDrillPlan />,
  },
  // {
  //   path: 'NcSummarySheet/:id',
  //   element: <ProcessAuditPlan_CreateSummarySheet />,
  // },
  //mock drill plan end
];
const clause10 = [
  // KPI
  {
    path: 'kpiList',
    element: <KpiList />,
  },
  {
    path: 'organizationChart',
    element: <OrganizationChart />,
  },
  {
    path: 'kpiCreate/:id?',
    element: <KpiCreate />,
  },
  // new plan
  {
    path: 'annualPMPlanMetallicBinTiles',
    element: <AnnualPMPlanMetallicBinTiles />,
  },
  {
    path: 'annualPMPlanMetallicBinPlanList',
    element: <AnnualPMPlanMetallicBinPlanList />,
  },
  {
    path: 'annualPMPlanMetallicBinCreate/:id',
    element: <AnnualPMPlanMetallicBinPlanCreate />,
  },
  {
    path: 'annualPMPlanMetallicBinStatus',
    element: <AnnualPMPlanMetallicBinStatus />,
  },
  {
    path: 'annualPMPlanMetallicBinChecklist',
    element: <AnnualPMPlanMetallicBinChecklist />,
  },
  {
    path: 'annualPMPlanMetallicBinStatusCheckList/:id?',
    element: <AnnualPMPlanMetallicBinStatusCheckList />,
  },
  {
    path: 'annualPMPlanMetallicBinChecklistCreate/:id?',
    element: <AnnualPMPlanMetallicBinChecklistCreate />,
  },
  // new plan
  {
    path: 'annualPMPlanTrollyTiles',
    element: <AnnualPMPlanTrollyTiles />,
  },
  {
    path: 'annualPMPlanTrollyPlanList',
    element: <AnnualPMPlanTrollyPlanList />,
  },
  {
    path: 'annualPMPlanTrollyCreate/:id',
    element: <AnnualPMPlanTrollyPlanCreate />,
  },
  {
    path: 'annualPMPlanTrollyStatus',
    element: <AnnualPMPlanTrollyStatus />,
  },
  {
    path: 'annualPMPlanTrollyChecklist',
    element: <AnnualPMPlanTrollyChecklist />,
  },
  {
    path: 'annualPMPlanTrollyStatusCheckList/:id?',
    element: <AnnualPMPlanTrollyStatusCheckList />,
  },
  {
    path: 'annualPMPlanTrollyChecklistCreate/:id?',
    element: <AnnualPMPlanTrollyChecklistCreate />,
  },
  {
    path: 'equipment_inspection_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'clause10',
    element: <Clause10 />,
  },
  //Man Machine Matrix
  {
    path: 'manMachineMatrixList',
    element: <ManMachineMatrixList />,
  },
  {
    path: 'manMachineMatrix/:departmentId',
    element: <ManMachineMatrix />,
  },
  {
    path: 'masterlistofmachine',
    element: <MasterListOfMachine />,
  },

  {
    path: 'machineHistory/:id',
    element: <CreateMachineHistory />,
  },
  {
    path: 'appearancePlanMachineHistory/:id',
    element: <AppearancePlanMachineHistoryCard />,
  },
  {
    path: 'masterlistofdepartment',
    element: <MasterListOfDepartment />,
  },
  // Die Casting
  {
    path: 'masterListDieCasting',
    element: <MasterListDieCasting />,
  },
  {
    path: 'annualPmDieCastingPlanTiles',
    element: <AnnualPmDieCastingPlanTiles />,
  },
  {
    path: 'annualPmDieCastingPlanList',
    element: <AnnualPmDieCastingPlanList />,
  },
  {
    path: 'annualPmDieCastingPlanCreate/:id',
    element: <AnnualPmDieCastingPlanCreate />,
  },
  {
    path: 'annualPmDieCastingPlanChecklistList',
    element: <AnnualPmDieCastingPlanChecklistList />,
  },
  {
    path: 'toolRoomTiles',
    element: <ToolRoomTiles />,
  },
  {
    path: 'moldList',
    element: <MoldList />,
  },
  {
    path: 'toolBreakdownRecord',
    element: <ToolBreakDownRecord />,
  },
  // break down slip
  {
    path: 'breakDownSlipList',
    element: <BreakDownSlipList />,
  },
  {
    path: 'createEditBreakDownSlip/:id?',
    element: <CreateBreakDownSlip />,
  },
  {
    path: 'beforeDieCastingCheckSheetList',
    element: <BeforeDieCastingCheckSheetList />,
  },
  {
    path: 'createBeforeDieCastingCheckSheet/:beforeDieCastingId?',
    element: <CreateBeforeDieCastingCheckSheet />,
  },
  {
    path: 'beforeDieCastingDailyCheckSheetList',
    element: <BeforeDieCastingDailyCheckSheetList />,
  },
  {
    path: 'createBeforeDieCastingDailyCheckSheet/:moldId/:beforeDieCastingDailyId?',
    element: <CreateBeforeDieCastingDailyCheckSheet />,
  },
  {
    path: 'masterListofProblems',
    element: <MasterListofProblems />,
  },
  {
    path: 'masterListofToolProblems',
    element: <MasterListofToolProblems />,
  },
  {
    path: 'breakDownRecordsList',
    element: <BreakDownRecordsList />,
  },

  {
    path: 'dieTrialRecord',
    element: <DieTrialRecord />,
  },
  {
    path: 'createDieTrialRecord/:id?',
    element: <CreateDieTrialRecord />,
  },
  {
    path: 'masterlistofToolsOrDie',
    element: <ToolsList />,
  },
  {
    path: 'toolsOrDieHistory/:id',
    element: <ToolsHistory />,
  },
  {
    path: 'monthlyBreakDownRecordsDashboard',
    element: <MonthlyBreakDownRecordsDashboard />,
  },
  //lux level sheet
  {
    path: 'luxLevelSheet',
    element: <LuxLevelSheetsList />,
  },
  {
    path: 'createLuxLevelSheet/:id?',
    element: <CreateLuxLevelSheetsList />,
  },
  {
    path: 'masterListofTrolly',
    element: <MasterListofTrolly />,
  },
  {
    path: 'masterListofMetallicBin',
    element: <MasterListofMetallicBin />,
  },

  {
    path: 'pressureGauge',
    element: <PressureGauge />,
  },

  // die loading checklist
  {
    path: 'dieLoadingChecklistTableList',
    element: <DieLoadingChecklistTableList />,
  },
  {
    path: 'dieLoadingChecklistCreate/:id?',
    element: <DieLoadingChecklistCreate />,
  },
  {
    path: 'masterListOfProblemsTiles',
    element: <MasterListOfProblemsTiles />,
  },
];

const hpdcChecksheets = [
  {
    path: 'hpdcChecksheets',
    element: <HPDCChecksheetsHome />,
  },
  {
    path: 'hpdcChecksheets/dailyMachineChecksheetList',
    element: <DailyMachineCheckSheetsList />,
  },
  {
    path: 'hpdcChecksheets/createDailyMachineChecksheet/:id?',
    element: <CreateDailyMachineCheckSheet />,
  },
  {
    path: 'hpdcChecksheets/operatorChecksheet',
    element: <OperatorCheckSheetsList />,
  },
  {
    path: 'hpdcChecksheets/createOperatorChecksheet/:id?',
    element: <CreateOperatorCheckSheet />,
  },

  {
    path: 'hpdcChecksheets/supervisorChecksheet',
    element: <SupervisorCheckSheetsList />,
  },
  {
    path: 'hpdcChecksheets/createSupervisorChecksheet/:id?',
    element: <CreateSupervisorCheckSheet />,
  },
];

const masterList = [
  {
    path: 'home/masterList',
    element: <MasterList />,
  },
  {
    path: 'home/masterList/pfd',
    element: <MasterListOfPfd />,
  },
  {
    path: 'home/masterList/pkgStd',
    element: <MasterListOfPackingStd />,
  },
  {
    path: 'home/masterList/CutSection',
    element: <MasterListofCutSection />,
  },
  {
    path: 'home/masterList/PFMEA',
    element: <MasterListofPFMEA />,
  },
  {
    path: 'home/masterList/ProcessSheet',
    element: <MasterListofProcessSheet />,
  },
];
const clause1 = [
  {
    path: 'devlopmentTracker',
    element: <DevlopmentTrackerList />,
  },
  {
    path: 'npdMatrixList',
    element: <MatrixList />,
  },
  {
    path: 'createNpdMatrix/:partId',
    element: <CreateMatrix />,
  },
  {
    path: 'npdTimePlanList',
    element: <TimePlanList />,
  },
  {
    path: 'createNpdTimePlan/:partId',
    element: <CreateNpdTimePlan />,
  },
  {
    path: 'npdPartList',
    element: <NpdPartList />,
  },
  {
    path: 'masterListOfProject',
    element: <MasterListOfProject />,
  },
];
//customer standard module
const customerRating = [
  {
    path: 'customerList',
    element: <CustomerList />,
  },
  {
    path: 'customerRatingList/:customerId',
    element: <CustomerRatingList />,
  },
];

export const IndexCommon: routes[] = [
  {
    path: 'clauseUploadList',
    element: <ClauseUploadList />,
  },
  {
    path: 'drawingManagement/:partId?',
    element: <DrawingnManagement />,
  },
  {
    path: 'maintenance_breakdown',
    element: <MaintenanceTiles />,
  },
  {
    path: 'maintenance_breakdown/preventive_maintenance_list',
    element: <PreventiveMaintenaceList />,
  },
  {
    path: 'maintenance_breakdown/preventive_maintenance_create/:id',
    element: <PreventiveMaintenaceCreate />,
  },
  {
    path: 'maintenance_breakdown/preventive_maintenance_check_list',
    element: <PreventiveMaintenaceCheckList />,
  },
  {
    path: 'maintenanceForCheckedMachineForm/:id/:checklistType',
    element: <PreventiveMaintenaceCheckListCreate />,
  },
  // {
  //   path: 'instrumentTiles',
  //   element: <InstrumentTiles />,
  // },
  // {
  //   path: 'instrumentplanlist',
  //   element: <PlanList />,
  // },
  // {
  //   path: 'instrumentplancreate/:id',
  //   element: <PlanCreate />,
  // },
  // {
  //   path: 'instrumentMasterlist',
  //   element: <InstrumentMasterList />,
  // },
  // {
  //   path: 'checklistList',
  //   element: <ChecklistList />,
  // },
  // Annual PM Plan
  {
    path: 'annualPmPlanRotorCastingTiles',
    element: <AnnualPmPlanRotorCastingTiles />,
  },
  {
    path: 'annualPmPlanRotorCastinglist',
    element: <AnnualPmPlanRotorCastingList />,
  },
  {
    path: 'annualPmPlanRotorCastingCreate/:id',
    element: <AnnualPmPlanRotorCastingCreate />,
  },
  {
    path: 'annualPmPlanRotorCastingMasterlist',
    element: <AnnualPmPlanRostorCastingMasterList />,
  },
  {
    path: 'annualPmPlanRotorCastingChecklist',
    element: <AnnualPmPlanRotorCastingChecklistList />,
  },
  // Annual PM Plan Utility
  {
    path: 'annualPmPlanUtilityTiles',
    element: <AnnualPmPlanUtilityTiles />,
  },
  {
    path: 'annualPmPlanUtilitylist',
    element: <AnnualPmPlanUtilityList />,
  },
  {
    path: 'annualPmPlanUtilityCreate/:id',
    element: <AnnualPmPlanUtilityCreate />,
  },
  // {
  //   path: 'annualPmPlanUtilityMasterlist',
  //   element: <AnnualPmPlanUtilityMasterList />,
  // },
  {
    path: 'annualPmPlanUtilityChecklist',
    element: <AnnualPmPlanUtilityChecklistList />,
  },
   {
    path: 'annualPmPlanUtility_Checklist',
    element: <AnnualPmPlanUtility_Checklist />,
  },
  {
    path: 'annualPmPlanUtility_ChecklistCreate/:id?',
    element: <AnnualPmPlanUtility_ChecklistCreate />,
  },
  {
    path: 'annulPmPlanUtiity_StatusCheckList/:id?',
    element: <AnnulPmPlanUtility_StatusCheckList />,
  },
  // {
  //   path: 'checklistList',
  //   element: <ChecklistList />,
  // },
  {
    path: 'maintenanceLog',
    element: <MaintenanceLogTiles />,
  },
  {
    path: 'breakDownLogBook',
    element: <BreakDownFinalList />,
  },
  {
    path: 'mtbf',
    element: <MtbfList />,
  },
  {
    path: 'mtbfCreate/:id',
    element: <MtbfCreate />,
  },
  {
    path: 'mttr',
    element: <MttrList />,
  },
  {
    path: 'mttrCreate/:id',
    element: <MttrCreate />,
  },
  {
    path: 'masterListOfCriticalSparePart',
    element: <CriticalSparePartsList />,
  },
  {
    path: 'appearanceplanMasterlist',
    element: <MasterListofAppearancePlan />,
  },
  // RI DEPARTMENT
  {
    path: 'home',
    element: <SupervisorHome />,
  },
  {
    path: 'riDepartment',
    element: <RIDeparmentHome />,
  },
  {
    path: 'riPart',
    element: <RIPart />,
  },
  // THIRD PARTY MATERIAL TEST PLAN
  {
    path: 'thirdPartyMaterialTestTiles',
    element: <ThirdPartyMaterialTestTiles />,
  },
  {
    path: 'thirdPartyMaterialTestPlanList',
    element: <ThirdPartyMaterialTestPlanList />,
  },
  {
    path: 'thirdPartyMaterialTestPlanCreate/:id',
    element: <ThirdPartyMaterialTestPlanCreate />,
  },
  {
    path: 'thirdPartyMaterialTestStatus',
    element: <ThirdPartyMaterialTestStatus />,
  },
  {
    path: 'thirdPartyMaterialTestChecklist',
    element: <ThirdPartyMaterialTestChecklist />,
  },
  {
    path: 'thirdPartyMaterialTestChecklistCreate/:id?',
    element: <ThirdPartyMaterialTestChecklistCreate />,
  },
  {
    path: 'thirdPartyMaterialTestStatusCheckList/:id?',
    element: <ThirdPartyMaterialTestStatusCheckList />,
  },
  {
    path: 'ri_department_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'samplingStandard',
    element: <SamplingStd />,
  },
  {
    path: 'clauseUploadList',
    element: <ClauseUploadList />,
  },
  {
    path: 'masterListOfVendor',
    element: <MasterListOfVendor />,
  },
  {
    path: 'inspectionStandardList',
    element: <InspectionStandardList />,
  },
  {
    path: 'inspectionStandard/:inspectionStandardId?',
    element: <CreateInpsectionStandard />,
  },
  {
    path: 'organization_chart_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'company_roles_responsibilities_listOfProcedures',
    element: <ListOfProcedures />,
  },

  //Mushin Help Desk
  {
    path: 'mushinHelpDesk',
    element: <MushinHelpDesk />,
  },

  {
    path: 'mrm/list',
    element: <MRMList />,
  },
  {
    path: 'mrm/create/:id?',
    element: <CreateMRMList />,
  },

  {
    path: 'listOfPokaYoke',
    element: <ListOfPokaYoke />,
  },
  // ---------X-------------
  ...inspectionReportRoute,
  {
    path: 'createDegassingCheckSheet',
    element: <CreateDegassingCheckSheet />,
  },
  // {
  //   path: 'checklist_actions_list?documentType=preventive',
  //   element: <MttrList />,
  // },
  // {
  //   path: 'mttrCreate/:id',
  //   element: <MttrCreate />,
  // },

  // process parameter std in production
  {
    path: 'processParameterStdList',
    element: <ProcessParameterList />,
  },
  {
    path: 'createProcessParameterStd/:partId/:id?',
    element: <CreateProcessParameterStd />,
  },

  ...clause5Eduction,
  ...forMSummary,
  ...clause3ForM,
  ...productions,
  ...clause8Modules,
  ...clause15Modules,
  ...clause5new,
  ...clause7,
  ...clause10,
  ...clause19,
  ...clause18,
  ...other,
  ...annualPmPlan,
  ...diePmPlan,
  ...supplierAuditPlan,
  ...hpdcChecksheets,
  ...qualityDepartment,
  ...masterList,
  ...clause6,
  ...processValidationRoute,
  ...processParameterSheetRoute,
  ...clause11Page,
  ...clause12Page,
  ...annualPmPlanNewUtility,
  ...clause1,
  ...customerRating,
];
