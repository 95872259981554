// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

export const TableRowComponent = React.memo(
  ({ key, item, index, allmonth, updateModuleData, handleDelete }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null,
    ) => {
      updateModuleData('row', index, fieldName, { [fieldName]: value });
    };

    return (
      <React.Fragment key={key}>
      <tr>
        <td>{index + 1}</td>
        <td colSpan={2}>
          <TableInput
            isView={isView}
            style={{}}
            name="checkpoint"
            type="text"
            value={item.checkpoint}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </td>
        <td>
          <TableInput
            isView={isView}
            style={{}}
            name="method"
            type="text"
            value={item.method}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </td>
        <td colSpan={2}>
          <TableInput
            isView={isView}
            style={{}}
            name="observation"
            type="text"
            value={item.observation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </td>
        <td>
          {isView ? (
            item?.status
          ) : (
            <CustomSelect
              label=""
              name="status"
              value={item?.status}
              onChange={(e: any) =>
                handleInputChange(e.target.name, e.target.value)
              }
            >
              <MenuItem value="OK">OK</MenuItem>
              <MenuItem value="NG">NG</MenuItem>
            </CustomSelect>
          )}
        </td>
      </tr>
    </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  },
);
